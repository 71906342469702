import { useEffect } from 'react';
import { REDIRECT_PARAMETER } from '../components/AuthGuard';
import { useSessionInfo } from '../hooks';
import { useRouter } from 'next/router';

const Home = () => {
  const { isSignedIn, userType, workspaceId, email, firstName, lastName } =
    useSessionInfo();

  const router = useRouter();
  const redirectPath = router.query?.[REDIRECT_PARAMETER] as string;

  useEffect(() => {
    if (isSignedIn) {
      if (redirectPath) {
        router.push(redirectPath);
      } else {
        if (userType === 'WorkspaceMember') {
          router.replace(`/workspace/${workspaceId}`);
        } else {
          router.replace('/environment');
        }
      }
    } else {
      router.push('/login');
    }
  }, [
    isSignedIn,
    userType,
    workspaceId,
    router,
    email,
    firstName,
    lastName,
    redirectPath,
  ]);

  return <></>;
};

export default Home;

Home.requireAuth = true;
